<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>
<style scoped>
>>>.v-data-table th {
  font-size: 15px;
}
</style>