import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'
import { RoleType } from './enums/Roles';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    // { path: '*', component: () => import("./views/Maintenance.vue") },
    // { path: '/*', component: () => import("./views/Maintenance.vue") },
    {
      path: "/",
      redirect: () => {
        switch (true) {
          case store.state.user && store.state.user.RoleID === RoleType.ADMIN:
            return "/admin";
          case store.state.user && (store.state.user.RoleID === RoleType.TEACHER || store.state.user.RoleID === RoleType.STATETEACHER || 
            store.state.user.RoleID === RoleType.LIMITEDSTATETEACHER): 
            return "/teacher";
          case store.state.user && store.state.user.RoleID === RoleType.STUDENT:
              return "/student";
          case store.state.user && store.state.user.RoleID === RoleType.CAMPUSDIRECTOR:
            return "/campus";
          case store.state.user && store.state.user.RoleID === RoleType.STATEDIRECTOR:
            return "/state";
          case store.state.user && store.state.user.RoleID === RoleType.CUSTOMERSERVICESUPPORT:
            return "/support"
        }
        return "/sign-in";
      }
    },
    {
      path: "/admin",
      redirect: { name: "admin-dashboard" },
      component: () => import("./layouts/AdminLayout.vue"),
      children: [
        { path: "dashboard", name: "admin-dashboard", component: () => import("./views/Dashboard.vue"), meta: { authRequired: true, authorize:[RoleType.ADMIN] }  },
        { path: "report-campus-management", name: "admin-report-campus-management", component: () => import("./views/ReportCampusManagement.vue"), meta: { authRequired: true, authorize:[RoleType.ADMIN] }  },
        { path: "report-student-management", name: "admin-report-student-management", component: () => import("./views/ReportStudentManagement.vue"), meta: { authRequired: true, authorize:[RoleType.ADMIN] }  },
        { path: "report-teacher-management", name: "admin-report-teacher-management", component: () => import("./views/ReportTeacherManagement.vue"), meta: { authRequired: true, authorize:[RoleType.ADMIN] }  },
        { path: "user-management", name: "admin-user-management", component: () => import("./views/UserManagement.vue"), meta: { authRequired: true, authorize:[RoleType.ADMIN] }  },
        { path: "award-management", name: "admin-award-management", component: () => import("./views/AwardManagement.vue"), meta: { authRequired: true, authorize:[RoleType.ADMIN] }  },
        { path: "homework", name: "admin-homework-marking", component: () => import("./views/HomeworkMarking.vue"), meta: { authRequired: true, authorize:[RoleType.ADMIN] }  },
      ],
    },
    {
      path: "/campus",
      redirect: { name: "campus-dashboard" },
      component: () => import("./layouts/AdminLayout.vue"),
      children: [
        { path: "dashboard", name: "campus-dashboard", component: () => import("./views/Dashboard.vue"), meta: { authRequired: true, authorize:[RoleType.CAMPUSDIRECTOR] }  },
        { path: "report-campus-management", name: "campus-report-campus-management", component: () => import("./views/ReportCampusManagement.vue"), meta: { authRequired: true, authorize:[RoleType.CAMPUSDIRECTOR] }  },
        { path: "report-student-management", name: "campus-report-student-management", component: () => import("./views/ReportStudentManagement.vue"), meta: { authRequired: true, authorize:[RoleType.CAMPUSDIRECTOR] }  },
        { path: "report-teacher-management", name: "campus-report-teacher-management", component: () => import("./views/ReportTeacherManagement.vue"), meta: { authRequired: true, authorize:[RoleType.CAMPUSDIRECTOR] }  },
        { path: "user-management", name: "campus-user-management", component: () => import("./views/UserManagement.vue"), meta: { authRequired: true, authorize:[RoleType.CAMPUSDIRECTOR] }  },
        { path: "award-management", name: "campus-award-management", component: () => import("./views/AwardManagement.vue"), meta: { authRequired: true, authorize:[RoleType.CAMPUSDIRECTOR] }  },
        { path: "homework", name: "campus-homework-marking", component: () => import("./views/HomeworkMarking.vue"), meta: { authRequired: true, authorize:[RoleType.CAMPUSDIRECTOR] }  },
      ],
    },
    {
      path: "/state",
      redirect: { name: "state-dashboard" },
      component: () => import("./layouts/AdminLayout.vue"),
      children: [
        { path: "dashboard", name: "state-dashboard", component: () => import("./views/Dashboard.vue"), meta: { authRequired: true, authorize:[RoleType.STATEDIRECTOR] }  },
        { path: "report-campus-management", name: "state-report-campus-management", component: () => import("./views/ReportCampusManagement.vue"), meta: { authRequired: true, authorize:[RoleType.STATEDIRECTOR] }  },
        { path: "report-student-management", name: "state-report-student-management", component: () => import("./views/ReportStudentManagement.vue"), meta: { authRequired: true, authorize:[RoleType.STATEDIRECTOR] }  },
        { path: "report-teacher-management", name: "state-report-teacher-management", component: () => import("./views/ReportTeacherManagement.vue"), meta: { authRequired: true, authorize:[RoleType.STATEDIRECTOR] }  },
        { path: "user-management", name: "state-user-management", component: () => import("./views/UserManagement.vue"), meta: { authRequired: true, authorize:[RoleType.STATEDIRECTOR] }  },
        { path: "award-management", name: "state-award-management", component: () => import("./views/AwardManagement.vue"), meta: { authRequired: true, authorize:[RoleType.STATEDIRECTOR] }  },
        { path: "homework", name: "state-homework-marking", component: () => import("./views/HomeworkMarking.vue"), meta: { authRequired: true, authorize:[RoleType.STATEDIRECTOR] }  },
      ],
    },
    {
      path: "/support",
      redirect: { name: "support-award-management" },
      component: () => import("./layouts/PublicLayout.vue"),
      children: [
        { path: "award-management", name: "support-award-management", component: () => import("./views/AwardManagement.vue"), meta: { authRequired: true, authorize:[RoleType.CUSTOMERSERVICESUPPORT] }  },
      ],
    },
    {
      path: "/teacher",
      redirect: { name: "teacher-homework-marking" },
      component: () => import("./layouts/PublicLayout.vue"),
      children: [
        { path: "homework", name: "teacher-homework-marking", component: () => import("./views/HomeworkMarking.vue"), meta: { authRequired: true, authorize:[RoleType.TEACHER, RoleType.STATETEACHER, RoleType.LIMITEDSTATETEACHER] }  },
      ],
    },
    {
      path: "/student",
      redirect: { name: "student-homework-upload" },
      component: () => import("./layouts/PublicLayout.vue"),
      children: [
        { path: "homework", name: "student-homework-upload", component: () => import("./views/HomeworkUpload.vue"), meta: { authRequired: true, authorize:[RoleType.STUDENT] } },
      ],
    },
    {
      path: '/sign-in',
      name: 'signin',
      redirect: { name: "/" },
      component: () => import("./layouts/PublicLayout.vue"),
      children: [
        { path: "/", name: "child-signin", component: () => import("./views/Signin.vue"), props: route => ({ errorMessage: route.query.errorMessage, paramUsername: route.query.studentnumber, paramPass: route.query.password }), meta: { authRequired: false }},
      ],
    },
    {
      path: '*',
      redirect: '/sign-in'
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('fetchToken');
  store.dispatch('fetchUser');
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.getters.isAuthenticated) {
        next({
            path: '/sign-in'
        });
    } else {
      // check if route is restricted by role
      if (to.meta.authorize.length && store.state.user && !to.meta.authorize.includes(store.state.user.RoleID)) {
        return next({ path: '/' });
      }
      next();
    }
  } else {
      next();
  }
});


export default router
