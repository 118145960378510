export const RoleType = {
  ADMIN: 1,
  TEACHER: 2,
  STUDENT: 3,
  CAMPUSDIRECTOR: 4,
  STATEDIRECTOR: 5,
  STATETEACHER: 6,
  LIMITEDSTATETEACHER: 7,
  CUSTOMERSERVICESUPPORT: 8
};

export default class Roles {}