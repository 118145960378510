import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router';
import { httpClient } from './services/http-client';
import { RoleType } from './enums/Roles';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    currentTerm: null,
    subjects: [],
    awards: [],
    homeworks: [],
    terms: [],
    years: [],
    regions: [],
    centres: [],
    staffs: [],
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setCurrentTerm(state, payload) {
      state.currentTerm = payload;
    },
    setSubjects(state, payload) {
      state.subjects = payload;
    },
    setAwards(state, payload) {
      state.awards = payload;
    },
    setHomeworks(state, payload) {
      state.homeworks = payload;
    },
    addNewHomeworks(state, payload) {
      state.homeworks = payload.concat(state.homeworks);
    },
    setTerms(state, payload) {
      state.terms = payload;
    },
    setYears(state, payload) {
      state.years = payload;
    },
    setRegions(state, payload) {
      state.regions = payload;
    },
    setCentres(state, payload) {
      state.centres = payload;
    },
    setStaffs(state, payload) {
      state.staffs = payload;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    addNewStaff(state, payload) {
      state.staffs.unshift(payload);
    },
    setStaff(state, { index, payload} ) {
      Object.assign(state.staffs[index], payload);
    },
    deleteStaff(state, payload) {
      const index = state.staffs.indexOf(payload);
      state.staffs.splice(index, 1);
    },
    deleteHomework(state, payload) {
      const index = state.homeworks.indexOf(payload);
      state.homeworks.splice(index, 1);
    },
    updateHomework(state, {editedItem, payload, hideAfterEdit}) {
      const index = state.homeworks.indexOf(editedItem);
      if (hideAfterEdit) 
        state.homeworks.splice(index, 1);
      else 
        Object.assign(state.homeworks[index], payload);
    },
    updateAwardHomework(state, {payload}) {
      state.homeworks = state.homeworks.map(obj => {
        if (obj.HomeworkID === payload.HomeworkID)
          return {
            ...obj,
            AwardID: payload.AwardID,
            AwardedBy: payload.AwardedBy,
            AwardedOn: payload.AwardedOn,
            IsAwardEnabled: 1,
          }
        else if (!obj.AwardID && obj.TermID === payload.TermID && obj.WeekID === payload.WeekID && obj.SubjectID === payload.SubjectID && 
          obj.Region_yearID === payload.Region_yearID && obj.StudentID === payload.StudentID)
          return {
            ...obj,
            IsAwardEnabled: 0
          }
        return obj;
      });
    },
    resetState(state) {
      state.token = null,
      state.user = null,
      state.currentTerm = null,
      state.subjects = [],
      state.awards = [],
      state.homeworks = [],
      state.terms = [],
      state.years = [],
      state.regions = [],
      state.centres = [],
      state.staffs = []
    }
  },
  actions: {
    async userLogin ({ commit }, { username, password }) {
      const response = await httpClient.post('/login', {username, password});
      if (response && response.data)
      {
        if (response.data.success) {
          const token = response.data.token;
          const user = response.data.user;         
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          commit('setToken', token);
          commit('setUser', user);
          router.push('/');
        } else {
          commit('setToken', null);
          commit('setUser', null);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          router.push(`/sign-in?errorMessage=${response.data.message}`).catch(() => {});
        }
      }
    },
    userSignOut ({ commit }) {
      commit('setToken', null);
      commit('setUser', null);
      commit('resetState');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      router.push('/');
    },
    fetchToken({ commit }) {
      try {
        commit('setToken', localStorage.getItem('token'));
      } catch(error) {
        commit('setToken', null);
      }
    },
    fetchUser({ commit }) {
      try {
        commit('setUser', JSON.parse(localStorage.getItem('user')));
      } catch(error) {
        commit('setUser', null);
      }
    },
    async getCurrentTerm({commit}) {
      try {
        const response = await httpClient.get('/currentTerm');
        let currentTerm = null;
        if (response && response.data) {
          currentTerm = response.data;
        }
        commit('setCurrentTerm', currentTerm);
      } catch(error)
      {
        commit('setCurrentTerm', null);
      }
    },
    async getSubjects({commit, state}) {
      if (state.subjects.length > 0) return;

      try {
        const response = await httpClient.get('/subjects');
        let subjects = null;
        if (response && response.data) {
          subjects = response.data;
        }
        commit('setSubjects', subjects);
      } catch(error)
      {
        commit('setSubjects', null);
      }
    },
    async getAwards({commit}) {
      try {
        const response = await httpClient.get('/awards');
        let awards = [];
        if (response && response.data) {
          awards = response.data;
        }
        commit('setAwards', awards);
      } catch(error)
      {
        commit('setAwards', []);
      }
    },
    async getStudentHomeworks({commit}) {
      try {
        const response = await httpClient.get('/homework/student');
        let homeworks = [];
        if (response && response.data) {
          homeworks = response.data;
        }
        commit('setHomeworks', homeworks);
      } catch(error)
      {
        commit('setHomeworks', []);
      }
    },
    async submitStudentHomework({ commit }, formData) {
      try {
        const response = await httpClient.post('/homework/student/upload', formData, { 
          headers: {'Content-Type': 'multipart/form-data'}
        });
        if (response && response.data)
        {
          commit('addNewHomeworks', response.data);
          return true;
        }
        return false;
      } catch(err) {
        console.log(err.response)
        return false;
      }
    },
    async saveStaff({ commit }, { params }) {
      try {
        const response = await httpClient.post('/staff', params)
        if (response && response.data)
        {
          commit('addNewStaff', response.data);
          return true;
        }
      } catch (err) {
        console.log(err.response)
        return false;
      }
    },
    async updateStaff({ commit }, { index, StaffID, params }) {
      try {
        const response = await httpClient.put(`/staff/${StaffID}`, params)
        if (response && response.data)
        {
          commit('setStaff', { index, payload: response.data} );
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response)
        return false;
      }
    },
    async deleteStaff({ commit }, { editedItem }) {
      try {
        const response = await httpClient.delete(`/staff/${editedItem.StaffID}`);
        if (response.status === 200) {
          commit('deleteStaff', editedItem);
          return true;
        }
        return false;  
      } catch (err) {
        console.log(err.response)
        return false;
      }
    },
    async getTerms({commit, state}) {
      if (state.terms.length > 0) return;

      try {
        const response = await httpClient.get('/terms');
        let terms = [];
        if (response && response.data) {
          terms = response.data;
        }
        commit('setTerms', terms);
      } catch(error)
      {
        commit('setTerms', []);
      }
    },
    async getYears({commit, state}) {
      if (state.years.length > 0) return;

      try {
        const response = await httpClient.get('/years');
        let years = [];
        if (response && response.data) {
          years = response.data;
        }
        commit('setYears', years);
      } catch(error)
      {
        commit('setYears', []);
      }
    },
    async getRegions({commit, state, getters}) {
      if (state.regions.length > 0) return;

      if (getters.allowRegionFilter) {
        try {
          const response = await httpClient.get('/regions');
          let regions = [];
          if (response && response.data) {
            regions = response.data;
          }
          commit('setRegions', regions);
        } catch(error)
        {
          commit('setRegions', []);
        }          
      }
    },
    async getCentres({commit, state, getters}) {
      if (state.centres.length > 0) return;

      if (getters.allowCentreFilter) {
        try {
          const response = await httpClient.get('/centres');
          let centres = [];
          if (response && response.data) {
            centres = response.data;
          }
          commit('setCentres', centres);
        } catch(error)
        {
          commit('setCentres', []);
        }  
      }
    },
    async getStaffs({commit}) {
      try {
        const response = await httpClient.get('/staffs');
        let staffs = [];
        if (response && response.data) {
          staffs = response.data;
        }
        commit('setStaffs', staffs);
      } catch(error)
      {
        commit('setStaffs', []);
      }
    },
    async searchHomeworks({commit}, params) {
      try {
        const response = await httpClient.get('/homework/marking', { params });
        let homeworks = [];
        if (response && response.data) {
          homeworks = response.data;
        }
        commit('setHomeworks', homeworks);
      } catch(error)
      {
        commit('setHomeworks', []);
      }
    },
    async deleteHomework({ commit }, { editedItem }) {
      try {
        const response = await httpClient.delete(`/homework/student/${editedItem.HomeworkID}`);
        if (response.status === 200) {
          commit('deleteHomework', editedItem);
          return true;
        }
        return false;  
      } catch (err) {
        console.log(err.response)
        return false;
      }
    },
    async updateHomework({ commit }, { editedItem, params, hideAfterEdit }) {
      try {
        const response = await httpClient.put(`/homework/student/${editedItem.HomeworkID}`, params)
        if (response && response.data)
        {
          commit('updateHomework', { editedItem, payload: response.data, hideAfterEdit} );
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response)
        return false;
      }
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    isAdmin: state => !!(state.user && state.user.RoleID === RoleType.ADMIN),
    isTeacher: state => !!(state.user && state.user.RoleID === RoleType.TEACHER),
    isStudent: state => !!(state.user && state.user.RoleID === RoleType.STUDENT),
    isCampusDirector: state => !!(state.user && state.user.RoleID === RoleType.CAMPUSDIRECTOR),
    isStateDirector: state => !!(state.user && state.user.RoleID === RoleType.STATEDIRECTOR),
    isStateTeacher: state => !!(state.user && state.user.RoleID === RoleType.STATETEACHER),
    isLimitedStateTeacher: state => !!(state.user && state.user.RoleID === RoleType.LIMITEDSTATETEACHER),
    isCustomerServiceSupport: state => !!(state.user && state.user.RoleID === RoleType.CUSTOMERSERVICESUPPORT),
    allowRegionFilter: state => !!(state.user && state.user.RoleID === RoleType.ADMIN),
    allowCentreFilter: state => !!(state.user && (state.user.RoleID === RoleType.ADMIN 
      || state.user.RoleID === RoleType.STATEDIRECTOR 
      || state.user.RoleID === RoleType.STATETEACHER 
      || state.user.RoleID === RoleType.LIMITEDSTATETEACHER)),
  }
})
