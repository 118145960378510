import moment from 'moment'
import Vue from 'vue'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY h:mm:ss A')
  }
})