import axios from 'axios';
import router from '@/router';
import { apiBaseUrl } from '@/environment/environment';

const config = {
  baseURL: apiBaseUrl
};

const httpClient = axios.create(config);

const getAuthToken = () => localStorage.getItem('token');

const authInterceptor = config => {
  config.headers['Authorization'] = getAuthToken();
  return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);

/** Adding the response interceptors */
const errorInterceptor = error => {
  // all the error responses
  switch(error.response.status) {
    case 400:
      console.error(error.response.status, error.message);
      //Vue.notify({type: 'warn', text: 'Nothing to display', title: 'Data Not Found'});
      break;

    case 401: // authentication error, logout the user
      localStorage.removeItem('token');
      router.push('/sign-in?errorMessage=Session Expired. Please login again');
      break;

    case 403:
      localStorage.removeItem('token');
      router.push('/sign-in?errorMessage=Invalid Username or Password');
      break;

    default:
      console.error(error.response.status, error.message);
      //Vue.notify({type: 'error', text: 'Server Error'});
  }
  return Promise.reject(error);
}

// Interceptor for responses
const responseInterceptor = response => {
  switch(response.status) {
      case 200: 
          // yay!
          break;
      // any other cases
      default:
          // default case
  }

  return response;
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export { httpClient };